import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import getComposeEnhancers from 'Services/redux/core__redux-dev-tools';
import { getRequest } from 'Services/http/core__axios';

export const dataReducer = (state = [], action) => {
  switch (action.type) {
    //Storing logged in customer information in redux store
    case 'CUSTOMER_DETAILS': {
      return {
        ...state,
        customerDetail: action.data,
      };
    }
    case 'CUSTOMER_CARD': {
      return {
        ...state,
        customerCard: action.data.customer.card,
      };
    }
    case 'CUSTOMER_BALANCE': {
      return {
        ...state,
        customerBalance: action.balance,
      };
    }
    case 'CASINO_BALANCE': {
      return {
        ...state,
        casinoBalance: action.balance,
      };
    }
    default:
      return state;
  }
};
//Combine reducers into a single reducer
export const reducer = combineReducers({
  data: dataReducer,
});
//Function to get the url for logged in customer details
export function getUrl(access_token) {
  const base = `/fsb-api-rest/customer`;
  let url = `${base}.fsb`;
  url = `${url}?access_token=${access_token}&details=Yes&betDetails=Yes`;
  return url;
}

export const setCustomerData = data => dispatch => {
  dispatch({ type: 'CUSTOMER_DETAILS', data });
};

//function to update account balance
export const updateAccountBalance = balance => dispatch => {
  dispatch({ type: 'CUSTOMER_BALANCE', balance });
};

//function to update casino balance
export const updateCasinoBalance = balance => dispatch => {
  dispatch({ type: 'CASINO_BALANCE', balance });
};

export const getCustomerCard = token => dispatch => {
  const base = `/fsb-api-rest/card`;
  const url = `${base}.fsb`;
  const fetchUrl = `${url}?access_token=${token}`;

  if (!token) return;

  const requester = getRequest;
  return requester(fetchUrl).then(res => {
    const data = res;
    dispatch({ type: 'CUSTOMER_CARD', data });
  });
};

const composeEnhancers = getComposeEnhancers({ name: 'my-account-store' });

export default initialState =>
  createStore(
    reducer,
    initialState,
    composeEnhancers(applyMiddleware(thunkMiddleware))
  );
