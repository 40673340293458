import React from 'react';

const DummyDefault = () => {
  return <React.Fragment />;
};

export const Dummy = () => {
  return <React.Fragment />;
};

export default DummyDefault;
