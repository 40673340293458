import { compose } from 'redux';

// attach to redux devtools browser extension but not on server or in production
// for reduxDevToolsOptions, see https://github.com/zalmoxisus/redux-devtools-extension/blob/master/docs/API/Arguments.md

const getComposeEnhancers = reduxDevToolsOptions =>
  (process.env.NODE_ENV !== 'production' &&
    typeof window !== 'undefined' &&
    typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === 'function' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(reduxDevToolsOptions)) ||
  compose;

export default getComposeEnhancers;
