import {
  BRB,
  BRBT,
  BRBBC,
  BRS,
} from 'CORE__UI/apps/BetslipUKApp/BetslipContainer/core__remainingBoosts';
import styled from 'styled-components';
import { BRAND, GREYS } from '../../../globals/colours';

const BRBJN = styled(BRB)`
  background-color: ${BRAND.secondary};
  border: none;
  color: ${GREYS.black};
`;
export { BRBJN as BRB, BRBT, BRBBC, BRS };
