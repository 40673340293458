import styled, { css } from 'styled-components';

export const PC = styled.div`
  position: static;
  right: 0;
  z-index: 9;
  width: 100%;
  background-color: transparent;
  height: 100vh;
  ${({ active }) =>
    active &&
    css`
      position: fixed;
      background-color: rgba(0, 0, 0, 0.5);
      top: 0;
    `}
`;
