import { GLOBAL_CONSTANTS } from 'Services/core__services';
import project from '../../../project';
import Constants from '../../../project';
import moment from 'moment';

export const bonusValues = {
  PENDING: 'PENDING',
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
  EXPIRED: 'EXPIRED',
  FORECAST: 'Forecast',
  TRICAST: 'Tricast',
  DECLINED: 'declined',
  DOUBLE: 'Double',
  APPROVED: 'approved',
  DEC: 'DECLINED',
  APPROVE: 'APPROVED',
  PENDING_STATE: 'Pending',
  APPROVE_STATE: 'Approved',
  DECLINE_STATE: 'Declined',
  ALL: 'All',
  ONE_MORE: 'One or more',
  ONE: 'One',
  SELECT: 'Select',
  PENDINGL: 'pending',
  CHECKBOX: 'checkbox',
  STR_s: 'str',
};

export const payMethod = {
  CARD: 'Debit/Credit Card',
  INTERAC_ETRANSFER: 'interac eTransfer',
  PAYSAFECARD: 'Paysafe card',
  PAYMENTIQ: 'PaymentIQ',
};

export const getPaymentName = payment => {
  switch (payment) {
    case 'PAYSAFE-CARD':
      return payMethod.CARD;
    case 'PAYSAFE-INTERAC_ETRANSFER':
      return payMethod.INTERAC_ETRANSFER;
    case 'PAYSAFE-PAYSAFECARD':
      return payMethod.PAYSAFECARD;
    default:
      return payment;
  }
};

// TODO: Editable not used correctly, this is Tech debt
export const setAccountDetails = (data, token) => {
  const date = data?.dob && new Date(data?.dob);
  let month = '';
  let day = '';
  let year = '';
  if (date) {
    const momentDate = moment(date);
    month = momentDate.format('M');
    day = momentDate.format('D');
    year = momentDate.format('YYYY');
  }
  return {
    title: {
      value: data?.title || '',
      editable: true,
    },
    userName: {
      value: data?.userName || '',
      editable: true,
    },
    emailId: {
      value: data?.email || '',
      editable: true,
    },
    firstName: {
      value: data?.firstName || '',
      editable: true,
    },
    middleName: {
      value: data?.middleName || '',
      editable: true,
      optional: true,
    },
    lastName: {
      value: data?.lastName || '',
      editable: true,
    },
    gender: {
      value: data?.gender || '',
      editable: true,
    },
    mobileNumber: {
      value: data?.mobile || '',
      editable: true,
    },
    dobYear: {
      value: year.toString(),
      editable: true,
    },
    dobDate: {
      value: day.toString(),
      editable: true,
    },
    dobMonth: {
      value: month.toString(),
      editable: true,
    },
    mfaEnabled: {
      value: data?.mfaEnabled || false,
      editable: true,
      optional: true,
    },
    balance: {
      value: `${data?.balance}`,
    },
    currencyCode: {
      value: data?.currencyCode,
    },
    birthNumber: {
      value: data?.birthNumber || '',
      editable: true,
      optional: true,
    },
    citizenship: {
      value: data?.citizenship || '',
      editable: true,
    },
    addressId: {
      value: data?.address?.[0].id || '',
      editable: true,
    },
    address: {
      value: data?.address?.[0].line1 || '',
      editable: true,
    },
    address2: {
      value: data?.address?.[0].line2 || '',
      editable: true,
      optional: true,
    },
    city: {
      value: data?.address?.[0].place || '',
      editable: true,
    },
    state: {
      value: data?.address?.[0].state || '',
      editable: true,
    },
    country: {
      value: data?.address?.[0].countryCode || '',
      editable: true,
    },
    postalCode: {
      value: data?.address?.[0].postcode || '',
      editable: true,
    },
    marketingOk: {
      value: data?.marketingOk || false,
      optional: true,
    },
    checkbox: data?.marketingOk || false,
    accessToken: token,
    idCardNumber: {
      value: (data?.idCardNumber ?? data?.passportNumber) || '',
      editable: true,
      isID: data?.idCardNumber,
    },
  };
};
export const setMetadataDetails = data => {
  const form = {
    preferredName: {
      value: data?.title || '',
      editable: true,
      error: false,
      name: 'preferred Name',
      optional: true,
    },
    dialingCode: {
      value: data?.title || '+1',
      editable: true,
      error: false,
      name: 'dialing code',
      optional: true,
    },
    employmentStatus: {
      value: data?.title || '',
      editable: true,
      error: false,
      name: 'employment status',
      optional: false,
    },
    employerName: {
      value: data?.title || '',
      editable: true,
      error: false,
      name: 'employer name',
      optional: true,
    },
    employerOccupation: {
      value: data?.title || '',
      editable: true,
      error: false,
      name: 'occupation',
      optional: false,
    },
    employerAddress: {
      value: data?.title || '',
      editable: true,
      error: false,
      name: 'employer address',
      optional: true,
    },
    employerAddress2: {
      value: data?.title || '',
      editable: true,
      error: false,
      name: 'employer address two',
      optional: true,
    },
    employerPostalCode: {
      value: data?.title || '',
      editable: true,
      error: false,
      name: 'employer postal code',
      optional: true,
    },
    employerCity: {
      value: data?.title || '',
      editable: true,
      error: false,
      name: 'employer city',
      optional: true,
    },
    employerState: {
      value: data?.title || 'ON',
      editable: true,
      error: false,
      name: 'employer state',
      optional: true,
    },
    employerBusinessNumber: {
      value: data?.title || '',
      editable: true,
      error: false,
      name: 'employer buisness number',
      optional: true,
    },
  };
  data?.forEach(s => {
    if (!form[s.type]) return;
    if (s.value) {
      form[s.type].value = s.value;
    } else {
      form[s.type].value = '';
    }
  });

  return form;
};

export const extendedToBasicKeysMap = {
  mobileNumber: 'mobile',
  address: 'line1',
  address2: 'line2',
  city: 'place',
  postalCode: 'postCode',
  country: 'countryCode',
};

export const defaultGamblingTabs = [
  'Deposit Limits',
  'Loss Limits',
  'Reality Check',
  'Casino Limit',
  'Timeout',
  'Self-Exclusion',
];

//Whenever we have additional tabs for specific brands
if (project.additionalGamblingTabs) {
  project.additionalGamblingTabs.forEach(el => {
    defaultGamblingTabs.push(el);
  });
}

export const Tabs = {
  SIM: 'sim',
  MY_BET: 'my-bets',
  FREE_BONUS: 'free-bonus',
  FREE_BET: 'free-bet',
  HISTORY: 'history',
  ACCOUNT: 'account',
  FREE: 'free',
  MY_LOTTO_BETS: 'my-lotto-bets',
  SPORTSBOOK: 'sportbook',
  CASINO: 'casino',
  CASHOUT: 'CASHOUT',
  CASHOUT_TAB: 'Cashout',
  CONFIRM_CASHOUT: 'CONFIRM CASHOUT',
  PARTIAL_CASHOUT: 'PARTIAL CASHOUT',
  GAMBLING: 'gambling',
  DEPOSITE_LIMIT: 'Deposit Limits',
  NET_DEPOSIT_LIMITS: 'Net Deposit Limits',
  REALITY_CHECK: 'Reality Check',
  CASINO_LIMIT: 'Casino Limit',
  TIMEOUT: 'Timeout',
  SELF_EXCLUSION: 'Self-Exclusion',
  PRODUCT_RESTRICTION: 'PRODUCT_RESTRICTION',
  CLOSE_ACCOUNT: 'Close Account',
  PERIOD: 'period',
  AMOUNT: 'amount',
  KYC: 'kyc',
  PROOF: 'proof',
  All: 'All',
  REFERENCE: 'reference',
  NOTE: 'note',
  BANKING: 'banking',
  HISTORY_TAB: 'Account History',
  WITHDRAW_TAB: 'Withdrawal History',
  BET_HISTORY_TAB: 'Bet History',
  GAME_HISTORY_TAB: 'Game History',
  TRUSTLY: 'TRUSTLY',
  OPEN_BET: 'Open Bets',
  SETTLED_BET: 'Settled Bets',
  DEPOSIT: 'deposit',
  WITHDRAW: 'withdraw',
  SELECTED_BET_TYPE: 'selectedBetType',
  DEFAULT_VALUE: '',
  PENDING: 'Pending',
  LOST: 'Lost',
  GAMBLING_TYPE: 'Select Deposit Limit',
  GAMBLING_AMOUNT: 'Select Desposit Amount',
  CASINO_LIMIT_TYPE: 'Select Limit Period',
  CASINO_LIMIT_AMOUNT: 'Select Limit Amount',
  SHOW: 'SHOW',
  PROCESSING_DATA: 'PROCESSING...',
  EACHWAY: 'EACHWAY',
  N_A: 'N/A',
  VOID: 'Void',
  ACCOUNT_LIMITS: 'Account Limits',
  LOSS_LIMITS: 'Loss Limits',
  SESSION_HISTORY_TAB: 'Session History',
  MY_ACCOUNT: 'my account',
  ACTIVATE_ACCOUNT: 'Activate Account',
  WITHDRAWAL: 'withdrawal',
  CASINO_LIMIT_TEXT: 'casinoLimit',
  CASINO_LIMIT_NUMERIC: 'casinoLimitNumeric',
  TERMS_AND_CONDITIONS: 'terms-and-conditions',
  SAFER_GAMBLING: 'responsible-gambling',
  STAKE_LIMIT: 'STAKE_LIMIT',
  BESPOKE_BETS: 'Bespoke Bets',
  CONTACT_PREFERENCES: 'Contact Preferences',
  ACCOUNT_CLOSURE: 'Account Closure',
};

export const TABS_TRANSLATION = {
  [Tabs.PRODUCT_RESTRICTION]: 'text.product.restriction',
  [Tabs.STAKE_LIMIT]: 'text.sportbook.stake.limit',
};

export const selfImposingTabs = ['Account Limits', 'Loss Limits', 'Timeout'];

export const GamblingLimit = {
  maxLimit: [
    { value: '10', label: '10.00' },
    { value: '25', label: '25.00' },
    { value: '50', label: '50.00' },
    { value: '100', label: '100.00' },
    { value: '150', label: '150.00' },
    { value: '250', label: '250.00' },
    { value: '500', label: '500.00' },
    { value: '1000', label: '1,000.00' },
    { value: '2000', label: '2,000.00' },
    { value: '5000', label: '5,000.00' },
    { value: '10000', label: '10,000.00' },
    { value: '20000', label: '20,000.00' },
    { value: '35000', label: '35,000.00' },
    { value: '50000', label: '50,000.00' },
    { value: '100000', label: '100,000.00' },
  ],
  minLimit: [
    { value: '5', label: '5.00' },
    { value: '10', label: '10.00' },
    { value: '25', label: '25.00' },
    { value: '50', label: '50.00' },
    { value: '100', label: '100.00' },
    { value: '500', label: '500.00' },
    { value: '1000', label: '1,000.00' },
  ],
  timePeriod: [
    { value: 'DAILY', label: 'Daily' },
    { value: 'WEEKLY', label: 'Weekly' },
    { value: 'MONTHLY', label: 'Monthly' },
  ],
  timePeriodNumeric: [
    { value: 'DAILY', label: '1 Day' },
    { value: 'WEEKLY', label: '7 Days' },
    { value: 'MONTHLY', label: '30 Days' },
  ],
  casinoLimit: [
    { value: '1440', label: '24 Hours' },
    { value: '10080', label: '7 Days' },
    { value: '43800', label: '1 month' },
  ],
  casinoLimitNumeric: [
    { value: '1440', label: '1 Day' },
    { value: '10080', label: '7 Days' },
    { value: '43800', label: '30 Days' },
  ],
  timeoutLimit: [
    { value: '1', label: '1 Day' },
    { value: '7', label: '1 Week' },
    { value: '30', label: '1 Month' },
    { value: '60', label: '2 Months' },
    { value: '90', label: '3 Months' },
  ],
  selfExclusion: [
    { value: 'SIX_MONTH', label: '6 Months' },
    { value: 'ONE_YEAR', label: '1 Year' },
    { value: 'TWO_YEAR', label: '2 Years' },
    { value: 'FIVE_YEAR', label: '5 Years' },
  ],
  timeoutLimitWeek: [
    { value: '1', label: '24 Hours' },
    { value: '7', label: '1 Week' },
    { value: '30', label: '1 Month' },
    { value: '42', label: '6 Weeks' },
  ],
  timeoutLimitOntario: [
    { value: '1', label: '1 Day', timeDifftype: 'd' },
    { value: '7', label: '1 Week', timeDifftype: 'd' },
    { value: '1', label: '1 Month', timeDifftype: 'M' },
    { value: '2', label: '2 Months', timeDifftype: 'M' },
    { value: '3', label: '3 Months', timeDifftype: 'M' },
  ],
  accountClosureReason: [
    { value: '1', label: 'Infrequent Bettor/Player' },
    { value: '2', label: 'Playing/Spending too much' },
    { value: '3', label: 'Unhappy with Service' },
    { value: '4', label: 'Unhappy with Products' },
    { value: '5', label: 'Technical Issue' },
    { value: '6', label: 'Payment Issue' },
    { value: '7', label: 'Unwilling to provide requested documents' },
    { value: '8', label: 'Other' },
  ],
};

export const OlympicDocs = [
  {
    name: 'text.proof.of.identity.one',
    status: 'Pending',
    active: false,
    required: 'One',
    groupId: 1,
    proofs: [
      {
        docName: 'text.id.two.sides',
        docNameStatic: 'ID - 2 sides',
        status: 'Select',
        active: false,
        type: 'IDENTITY_CARD',
        document: [],
      },
      {
        docName: 'text.passport.one.side',
        docNameStatic: 'Passport - 1 side',
        status: 'Select',
        active: true,
        type: 'PASSPORT',
        document: [],
      },
      {
        docName: 'text.residence.permit',
        docNameStatic: 'Residence Permit (foreigners*)-2 sides',
        status: 'Select',
        active: false,
        type: 'RESIDENCE_PERMIT',
        document: [],
      },
    ],
  },
  {
    name: 'text.proof.of.identity.two',
    required: 'One',
    status: 'Pending',
    groupId: 2,
    proofs: [
      {
        docName: 'text.id.if.not.submitted.previously',
        docNameStatic: 'ID (if not submitted previously)- 2 sides',
        status: 'Select',
        active: false,
        type: 'IDENTITY_CARD',
        document: [],
      },
      {
        docName: 'text.passport.if.not.submitted.previously',
        docNameStatic: 'Passport (if not submitted previously)- 1 side',
        status: 'Select',
        active: true,
        type: 'PASSPORT',
        document: [],
      },
      {
        docName: 'text.residence.permit.if.not.submitted.previously',
        docNameStatic:
          'Residence Permit(if not submitted previously)(foreigners*)-2 sides',
        status: 'Select',
        active: false,
        type: 'RESIDENCE_PERMIT',
        document: [],
      },
      {
        docName: 'text.driving.licence',
        docNameStatic: 'Driving Licence',
        status: 'Select',
        active: false,
        type: 'DRIVING_LICENSE',
        document: [],
      },

      {
        docName: 'text.eu.health.card',
        docNameStatic: 'Eu Health Card - 1 side',
        status: 'Select',
        active: false,
        type: 'OTHERS',
        document: [],
      },
    ],
  },
  {
    name: 'text.documents.for.aml',
    required: 'Required on demand of OlyBet',
    status: 'Pending',
    groupId: 3,
    proofs: [
      {
        docName: 'text.payslip',
        docNameStatic: 'Payslip',
        status: 'Select',
        active: false,
        type: 'PAYSLIP',
        document: [],
      },
      {
        docName: 'text.bank.account.statement',
        docNameStatic: 'Bank Account Statement',
        status: 'Select',
        active: false,
        type: 'BANK_STATEMENT',
        document: [],
      },
      {
        docName: 'text.other.documents',
        docNameStatic: 'Other Documents',
        status: 'Select',
        active: false,
        type: 'OTHERS',
        document: [],
      },
    ],
  },
];

export const KycDocs = [
  {
    name: 'Proof of Identity',
    status: 'Pending',
    active: false,
    required: 'One',
    proofs: [
      {
        docName: 'Passport',
        status: 'Select',
        active: true,
        type: 'PASSPORT',
        document: [],
      },
      {
        docName: 'Driving Licence',
        status: 'Select',
        active: false,
        type: 'DRIVING_LICENSE',
        document: [],
      },
      {
        docName: 'National identification Card',
        status: 'Select',
        active: false,
        type: 'IDENTITY_CARD',
        document: [],
      },
    ],
  },
  {
    name: 'Proof of Address',
    required: 'One',
    status: 'Pending',
    proofs: [
      {
        docName: 'Utility Bill',
        status: 'Select',
        active: true,
        type: 'UTILITY_BILL',
        document: [],
        trans: 'text.utility.bill',
      },
      {
        docName: 'Council Tax',
        status: 'Select',
        active: false,
        type: 'COUNCIL_TAX',
        document: [],
        trans: 'text.utility.bill',
      },
      {
        docName: 'Copy of Bank Statement',
        status: 'Select',
        active: false,
        type: 'BANK_REFERENCE',
        document: [],
        trans: 'text.utility.bill',
      },
    ],
  },
  {
    name: 'Source of Funds',
    status: 'Pending',
    required: 'All',
    proofs: [
      {
        docName: 'Bank Statement',
        status: 'Select',
        active: false,
        type: 'BANK_STATEMENT',
        document: [],
        trans: 'text.bank.statement',
      },
      {
        docName: 'Debit Card',
        status: 'Select',
        active: false,
        type: 'DEBIT_CREDIT_CARD',
        document: [],
        trans: 'text.kyc_credit.card',
      },
      {
        docName: 'Additional Payment Methods',
        status: 'Select',
        active: false,
        type: 'ADDITIONAL_PAYMENT_METHODS',
        document: [],
        trans: 'text.additional.payment.method',
      },
    ],
  },
  {
    name: 'Further Verification',
    required: 'One or more',
    status: 'Pending',
    proofs: [
      {
        docName: 'Payslip',
        status: 'Select',
        active: false,
        type: 'PAYSLIP',
        document: [],
      },
      {
        docName: 'Certificate of Employment',
        status: 'Select',
        active: false,
        type: 'CERTIFICATE_OF_EMPLOYMENT',
        document: [],
      },
      {
        docName: 'Tax return / P60',
        status: 'Select',
        active: false,
        type: 'TAX_RETURN_P60',
        document: [],
      },

      {
        docName: 'Other Documents*',
        status: 'Select',
        active: false,
        type: 'OTHERS',
        document: [],
      },
    ],
  },
];

export const AddressLine = (data, field, address) => {
  const addressObject = {
    line1: {
      value: address?.line1?.value || '',
      error: '',
    },
    line2: {
      value: address?.line2?.value || '',
      error: '',
    },
    place: {
      value: address?.place?.value || '',
      error: '',
    },
    countryCode: {
      value: address?.countryCode?.value || 'GB',
      error: '',
    },
    postalCode: {
      value: address?.postalCode?.value || '',
      error: '',
    },
  };
  if (field) {
    addressObject[field].value = data?.target?.value || data?.value;
  }
  return addressObject;
};

export const clientURL = {
  baseUrl: Constants.staticPath.basePath,
  image: `${
    Constants.staticPath.bucketImages + Constants.staticPath.folderPayments
  }`,
  fsb: '/fsb-api-rest',
  contactUs: '/contact-us/',
};

export const providerCards = () => {
  return [
    {
      name: 'Debit/Credit Card',
      img: `${clientURL.baseUrl}${Constants.staticPath.bucketReact}payment/visa-mastercard.png`,
      ref: 'SECURETRADING',
    },
    {
      name: 'Skrill',
      img: `${clientURL.baseUrl}${clientURL.image}icon-payment-skrill.svg`,
      ref: 'SKRILL',
    },
    {
      name: 'Trustly',
      img: `${clientURL.baseUrl}${clientURL.image}icon-payment-trustly.svg`,
      ref: 'TRUSTLY',
    },
    {
      name: 'Neteller',
      img: `${clientURL.baseUrl}${clientURL.image}icon-payment-paysafe.svg`,
      ref: 'PAYSAFE',
    },
    {
      name: 'Neteller',
      img: `${clientURL.baseUrl}${clientURL.image}icon-payment-paysafe.svg`,
      ref: 'NETTELER_PAYSAFE',
    },
    {
      name: 'Cash',
      img: `${clientURL.baseUrl}${clientURL.image}icon-payment-cash.svg`,
      ref: 'CASH',
    },
    {
      name: 'Paypal',
      img: `${clientURL.baseUrl}${clientURL.image}icon-payment-paypal.svg`,
      ref: 'PAYPAL',
    },
    {
      name: 'REALEX',
      img: `${clientURL.baseUrl}${clientURL.image}icon-payment-mastercard-wd.svg`,
      ref: 'REALEX',
    },
    {
      name: 'CORVUS',
      img: `${clientURL.baseUrl}/olympic/icons/corvuspay.png`,
      ref: 'Corvus',
    },
  ];
};

export const providerCardsWithdraw = () => {
  return [
    {
      img: `${clientURL.baseUrl}${Constants.staticPath.bucketReact}payment/visa-mastercard.png`,
      ref: 'VISA',
    },
    {
      img: `${clientURL.baseUrl}${clientURL.image}icon-payment-visa.svg`,
      ref: 'AMEX',
    },
    {
      img: `${clientURL.baseUrl}${Constants.staticPath.bucketReact}payment/visa-mastercard.png`,
      ref: 'SECURE-TRADING',
    },
    {
      img: `${clientURL.baseUrl}${clientURL.image}icon-payment-skrill.svg`,
      ref: 'SKRILL',
    },
    {
      img: `${clientURL.baseUrl}${clientURL.image}icon-payment-trustly.svg`,
      ref: 'TRUSTLY',
    },
    {
      img: `${clientURL.baseUrl}${clientURL.image}icon-payment-paysafe.svg`,
      ref: 'PAYSAFE',
    },
    {
      img: `${clientURL.baseUrl}${clientURL.image}icon-payment-cash.svg`,
      ref: 'CASH',
    },
    {
      img: `${clientURL.baseUrl}${clientURL.image}icon-payment-paypal.svg`,
      ref: 'PAYPAL',
    },
    {
      img: `${clientURL.baseUrl}${Constants.staticPath.bucketReact}payment/visa-mastercard.png`,
      ref: 'MASTERCARD',
    },
    {
      img: `${clientURL.baseUrl}${clientURL.image}icon-payment-credit-card.svg`,
      ref: 'REALEX',
    },
  ];
};

export const actionType = {
  ACCESS_TOKEN: 'ACCESS_TOKEN',
  CUSTOMER_DETAILS: 'CUSTOMER_DETAILS',
  CLOSE_USER_ACCOUNT: 'CLOSE_USER_ACCOUNT',
  CUSTOMER_EXTENDED_DETAILS: 'CUSTOMER_EXTENDED_DETAILS',
  UPDATE_PHONE: 'UPDATE_PHONE',
  UPDATE_CHECKBOX: 'UPDATE_CHECKBOX',
  UPDATE_MFA: 'UPDATE_MFA',
  MY_BET_DATA: 'MY_BET_DATA',
  CASHOUT_DATA: 'CASHOUT_DATA',
  SUCCESS_CHECKOUT: 'SUCCESS_CHECKOUT',
  PROCESSING: 'PROCESSING',
  ACTIVE_BONUS: 'ACTIVE_BONUS',
  ACTIVE_BET: 'ACTIVE_BET',
  SELECT_BET_ID: 'SELECT_BET_ID',
  SELECTED_TAB: 'SELECTED_TAB',
  SELECTED_BONUS_BET: 'SELECTED_BONUS_BET',
  GAME_HISTORY: 'GAME_HISTORY',
  BET_HISTORY: 'BET_HISTORY',
  FREE_BET: 'FREE_BET',
  SHOW_PASSWORD: 'SHOW_PASSWORD',
  NEW_PASSWORD: 'NEW_PASSWORD',
  CURRENT_PASSWORD: 'CURRENT_PASSWORD',
  CONFIRM_PASSWORD: 'CONFIRM_PASSWORD',
  PASSWORD_RESET: 'PASSWORD_RESET',
  VOCHER_DATA: 'VOCHER_DATA',
  OPT_OUT: 'OPT_OUT',
  DEPOSITE_LIMIT: 'DEPOSITE_LIMIT',
  DEPOSIT_ERROR: 'DEPOSIT_ERROR',
  DEPOSIT_PERIOD: 'DEPOSIT_PERIOD',
  DEPOSIT_AMOUNT: 'DEPOSIT_AMOUNT',
  CASINO_PERIOD: 'CASINO_PERIOD',
  CASINO_AMOUNT: 'CASINO_AMOUNT',
  REALITY_LIMIT: 'REALITY_LIMIT',
  REALITY_INPUT: 'REALITY_INPUT',
  REALITY_VALUE: 'REALITY_VALUE',
  CASINO_LIMIT: 'CASINO_LIMIT',
  CLOSE_ACCOUNT: 'CLOSE_ACCOUNT',
  DOCUMENT_TYPES: 'DOCUMENT_TYPES',
  DOCUMENTS_TYPEOLY: 'DOCUMENTS_TYPEOLY',
  DOCUMENT_UPLOAD: 'DOCUMENT_UPLOAD',
  UPLOADED_FILES: 'UPLOADED_FILES',
  FIELD_REFERNCE_KYC: 'FIELD_REFERNCE_KYC',
  FIELD_NOTE_KYC: 'FIELD_NOTE_KYC',
  UPDATE_LIMIT_VALUE: 'UPDATE_LIMIT_VALUE',
  BANKING_ADDRESS: 'BANKING_ADDRESS',
  ADDRESS_BANK: 'ADDRESS_BANK',
  ALLOWED_PROVIDES: 'ALLOWED_PROVIDES',
  ACTIVE_PAYMENT: 'ACTIVE_PAYMENT',
  FUND_HISTORY: 'FUND_HISTORY',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  DEFAULT: 'DEFAULT',
  TIMEOUT_PERIOD: 'TIMEOUT_PERIOD',
  SHOW_ADDRESS: 'SHOW_ADDRESS',
  PARTIAL_VALUE: 'PARTIAL_VALUE',
  SELECT_BANK: 'SELECT_BANK',
  ACCOUNT_HISTORY: 'ACCOUNT_HISTORY',
  END_DATE: 'END_DATE',
  START_DATE: 'START_DATE',
  ACCOUNT_SUMMARY: 'ACCOUNT_SUMMARY',
  CUSTOMER_ACCOUNT_SUMMARY: 'CUSTOMER_ACCOUNT_SUMMARY',
  WITHDRAW_HISTORY: 'WITHDRAW_HISTORY',
  SHOW_FULL_ADDRESS: 'SHOW_FULL_ADDRESS',
  DEFAULT_DATE: 'DEFAULT_DATE',
  GAME_HISTORY_DATA: 'GAME_HISTORY_DATA',
  LOOKUP_ADDRESS: 'LOOKUP_ADDRESS',
  ALLOWED_WITHDRAW_PROVIDES: 'ALLOWED_WITHDRAW_PROVIDES',
  CLOSE_WITHDRAW_INFO: 'CLOSE_WITHDRAW_INFO',
  FIELD_WITHDRAW: 'FIELD_WITHDRAW',
  SUCCESS_WIHDRAW: 'SUCCESS_WIHDRAW',
  CLOSE_WITHDRAW_POPUP: 'CLOSE_WITHDRAW_POPUP',
  TRUSTLY_IFRAME: 'TRUSTLY_IFRAME',
  SET_START: 'SET_START',
  SET_END: 'SET_END',
  CASINO_BONUS: 'CASINO_BONUS',
  ACTIVE_BONUS_CASINO: 'ACTIVE_BONUS_CASINO',
  OPEN_KYC: 'OPEN_KYC',
  CLOSE_DEPOSIT: 'CLOSE_DEPOSIT',
  UPDATE_DEPOSIT: 'UPDATE_DEPOSIT',
  CLOSE_TIMEOUT: 'CLOSE_TIMEOUT',
  TIMEOUT_SUCCESS: 'TIMEOUT_SUCCESS',
  CLOSE_SELF_EXCLUSION: 'CLOSE_SELF_EXCLUSION',
  SELF_EXCLUSION_SUCCESS: 'SELF_EXCLUSION_SUCCESS',
  ADDRESS_ERROR: 'ADDRESS_ERROR',
  REALITY_LIMIT_ERROR: 'REALITY_LIMIT_ERROR',
  ACTIVE_ADDRESS: 'ACTIVE_ADDRESS',
  LOADER: 'LOADER',
  REALITY_PERIOD: 'REALITY_PERIOD',
  GB: 'GB',
  REF_VALIDATION_ERROR: 'REF_VALIDATION_ERROR',
  UPDATE_CASINO: 'UPDATE_CASINO',
  KYC_ERROR_EXCEED_SIZE: 'KYC_ERROR_EXCEED_SIZE',
  KYC_ERROR_INVALID_TYPE: 'KYC_ERROR_INVALID_TYPE',
  CASHOUT_ERROR: 'CASHOUT_ERROR',
  WITHDRAW_ERROR: 'WITHDRAW_ERROR',
  WITHDRAW_FIELDS: 'WITHDRAW_FIELDS',
  WITHDRAW_API_ERROR: 'WITHDRAW_API_ERROR',
  CASHOUT_ERROR_REQUEST: 'CASHOUT_ERROR_REQUEST',
  CASHOUT_ID: 'CASHOUT_ID',
  ACTIVATE_BUTTON: 'ACTIVATE_BUTTON',
  REALITY_POPUP: 'REALITY_POPUP',
  LOSS_LIMITS: 'LOSS_LIMITS',
  PUT_LOSS_LIMITS: 'PUT_LOSS_LIMITS',
  ACCOUNT_LIMITS: 'ACCOUNT_LIMITS',
  PUT_ACCOUNT_LIMITS: 'PUT_ACCOUNT_LIMITS',
  DELETE_LOSS_LIMITS: 'DELETE_LOSS_LIMITS',
  DELETE_ACCOUNT_LIMITS: 'DELETE_ACCOUNT_LIMITS',
  IBAN_CARDS: 'IBAN_CARDS',
  IBAN_WITHDROW_RESPONSE: 'IBAN_WITHDROW_RESPONSE',
  LIMITS_DATA: 'LIMITS_DATA',
  UPDATE_TITLE: 'UPDATE_TITLE',
  SESSION_HISTORY: 'SESSION_HISTORY',
  CANCEL_WITHDRAW: 'CANCEL_WITHDRAW',
  OPEN_POPUP: 'OPEN_POPUP',
  CLOSE_POPUP: 'CLOSE_POPUP',
  ERROR_MYBET: 'ERROR_MYBET',
};

export const resetWithdrawData = withdraw => {
  let obj;
  withdraw?.allowedPayments?.card?.map((x, i) => {
    if (x.methodRef === Tabs.TRUSTLY) {
      obj = withdraw.allowedPayments.card.splice(i, 1);
    }
    return x;
  });
  if (obj) withdraw.allowedPayments.card.unshift(obj[0]);
  return withdraw;
};

export const AddressFields = {
  ADDRESS_LINE1: 'text.addressone',
  ADDRESS_LINE2: 'text.addresstwo',
  CITY: 'text.city',
  POSTAL_CODE: 'text.postcode',
  COUNTRY_CODE: 'text.countrycode',
  CONTINUE: 'text.continue',
  ADD_ADDRESS: 'text.add.address',
  LINE1: 'line1',
  LINE2: 'line2',
  PLACE: 'place',
  POSTAL: 'postalCode',
  COUNTRY: 'countryCode',
};

export const momentFormat = {
  UK: project.timeFormats.accounts.primary || 'DD/MM/YYYY h:mm',
};

export const timeDuration = {
  HOURS: '24 Hours',
  DAYS: '7 Days',
  MONTH: '1 Month',
  SIXMONTH: '6 Months',
};

export const addressField = {
  line1: 'Street 1',
  line2: 'Unit B-E Level 2',
  postcode: 'BN1 4NW',
  place: 'Brighton',
};
export const Constant_URLS = {
  medium_protection:
    'https://www.gamblingcommission.gov.uk/public-and-players/guide/page/if-your-money-has-medium-protection',
  gambling: 'https://www.gamcare.org.uk/get-support/talk-to-us-now/',
  route: 'https://www.begambleaware.org/',
};

export const FILE_FORMAT = {
  jpg: 'image/jpg',
  jpeg: 'image/jpeg',
  png: 'image/png',
  pdf: 'application/pdf',
};
export const data = {
  kycDocuments: [
    {
      name: 'Proof of Identity',
      status: 'Pending',
      active: false,
      required: 'One',
      proofs: [
        {
          docName: 'Passport',
          status: 'Select',
          active: true,
          type: 'PASSPORT',
          document: [],
        },
        {
          docName: 'Driving Licence',
          status: 'Select',
          active: false,
          type: 'DRIVING_LICENSE',
          document: [],
        },
        {
          docName: 'National identification Card',
          status: 'Select',
          active: false,
          type: 'IDENTITY_CARD',
          document: [],
        },
      ],
    },
    {
      name: 'Proof of Address',
      required: 'One',
      status: 'Pending',
      proofs: [
        {
          docName: 'Utility Bill',
          status: 'Select',
          active: true,
          type: 'UTILITY_BILL',
          document: [],
          trans: 'text.utility.bill',
        },
        {
          docName: 'Council Tax',
          status: 'Select',
          active: false,
          type: 'COUNCIL_TAX',
          document: [],
          trans: 'text.utility.bill',
        },
        {
          docName: 'Copy of Bank Statement',
          status: 'Select',
          active: false,
          type: 'BANK_REFERENCE',
          document: [],
          trans: 'text.utility.bill',
        },
      ],
    },
  ],
  OlympicDocument: [
    {
      docName: 'text.id.two.sides',
      docNameStatic: 'ID - 2 sides',
      status: 'Select',
      active: false,
      type: 'IDENTITY_CARD',
      document: [],
    },
  ],
  openUploadPopup: false,
  uploadedFiles: {
    name: 'name',
    accessToken: '992c11c5-60e4-46f3-800c-5bf980d73804',
  },
  accessToken: '992c11c5-60e4-46f3-800c-5bf980d73804',
  refernceKyc: '',
};
export const kycStatus = {
  APPROVED: 'Approved',
  DECLINED: 'Declined',
  PENDING: 'Pending',
  EXPIRED: 'Expired',
};

export const defaultDepositLimits = {
  maxdeposit: 5000,
  mindeposit: 10,
};

/* default value of max file size */
export const initalMaxFileSize = 5000000;

export const monthDurationInMillisec = 2629800000;

export const WIN = 'WIN';
export const WON = 'WON';
export const twoFactorAuthInputs = [
  { id: 'field1', switchTo: 'field2', value: '' },
  { id: 'field2', switchTo: 'field3', value: '' },
  { id: 'field3', switchTo: 'field4', value: '' },
  { id: 'field4', switchTo: 'field5', value: '' },
  { id: 'field5', switchTo: 'field6', value: '' },
  { id: 'field6', switchTo: 'field6', value: '' },
];

export const cardData = data => {
  return {
    card: data,
    error: '',
    value: '',
  };
};

//function to calculate total amount user wants to withdraw from all cards
export const calcTotalWithdrawlAmount = obj => {
  if (Array.isArray(obj)) {
    let sum = 0;
    obj.forEach(item => {
      if (typeof item.value === GLOBAL_CONSTANTS.STRING) {
        sum += parseFloat(item.value);
      } else if (typeof item.value === GLOBAL_CONSTANTS.NUMBER) {
        sum += item.value;
      }
    });
    return sum;
  } else {
    if (typeof obj.value === GLOBAL_CONSTANTS.STRING) {
      return parseFloat(obj.value);
    } else if (typeof obj.value === GLOBAL_CONSTANTS.NUMBER) {
      return obj.value;
    }
  }
};
