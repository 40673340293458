import project from '../project';
import { BREAKPOINT_L } from 'UI/globals/breakpoints';

export const detectMob = (getOS = false) => {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];
  if (getOS) {
    let matched = null;
    toMatch.forEach(toMatchItem => {
      if (navigator?.userAgent?.match(toMatchItem)) {
        matched = toMatchItem;
      }
    });
    return matched;
  }
  return toMatch.some(toMatchItem => {
    return navigator?.userAgent?.match(toMatchItem);
  });
};
export const setPageParamsToConfig = appConfig => {
  try {
    const bodyTag =
      document.getElementsByTagName('body') &&
      document.getElementsByTagName('body')[0];
    if (bodyTag.attributes) {
      for (let i = 0; i < bodyTag.attributes.length; i++) {
        const attr = bodyTag.attributes[i];
        const key = attr.name.replace('data-', '');
        appConfig[key] = attr.value;
      }
    }
  } catch (e) {
    //no handling required
  }
  return appConfig;
};
export const setSessionStorage = () => {
  let url = window.sessionStorage.getItem('root');
  if (url) {
    url = JSON.parse(url);
    if (url[url.length - 1] !== window.location.href) {
      url.push(window.location.href);
      if (url.length > 2) {
        url = url.slice(url.length - 2, url.length);
      }
    }
  } else {
    url = [window.location.href];
  }
  window.sessionStorage.setItem('root', JSON.stringify(url));
  try {
    window.history.pushState(
      { xyz: Math.random() },
      document.title,
      window.location.href
    );
  } catch (err) {
    // no thing todo
  }
};
try {
  if (!project.redirectRestriction) {
    setSessionStorage();
  }
} catch (error) {
  //no handling required
}

export const setAppConfig = appSection => {
  return Object.entries(appSection.dataset).reduce((acc, [k, v]) => {
    // If theres no value, return object
    if (!v) return acc;

    // If value is a number and doesn't contain '+', return value parsed as number
    if (!isNaN(+v) && !`${v}`.includes('+')) return { ...acc, [k]: +v };

    // If value is string true, return boolean true
    if (v === 'true') return { ...acc, [k]: true };

    // If value is string false, return boolean false
    if (v === 'false') return { ...acc, [k]: false };

    // If none of the above, return value as it is
    return { ...acc, [k]: v };
  }, {});
};

// detect Mobile device
export const detectMobile = () => {
  try {
    return window.innerWidth <= parseInt(BREAKPOINT_L);
  } catch (error) {
    return null;
  }
};
