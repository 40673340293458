import styled from 'styled-components';
import { BRAND, FONT, GREYS } from 'UI/globals/colours';

export const MAC = styled.div`
  width: 280px;
  min-height: ${({ fullHeight }) => (fullHeight ? '420px' : '0px')};
  max-height: ${({ isIphone }) =>
    isIphone ? `calc(100vh - 25vh)` : `calc(100vh - 64px)`};
  overflow: auto;
  padding: 12px 16px 12px 16px;
  position: absolute;
  right: 24px;
  background-color: ${GREYS.white};
  top: 64px;
  z-index: 1;
  border-radius: 4px;
  cursor: default;
  animation: 300ms ease 0s 1 normal none running
    react-responsive-modal-overlay-in;
`;

export const ANC = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

export const AC = styled.div`
  color: ${FONT.primary};
  font-size: 18px;
  font-weight: bold;
`;

export const AP = styled.div`
  color: ${FONT.primary};
  font-size: 14px;
  font-weight: bold;
`;
export const BS = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
`;
export const BN = styled.div`
  font-size: 14px;
  color: ${FONT.dark};
  margin-left: ${({ showIcon }) => (showIcon ? `4px` : 0)};
`;
export const BA = styled.div`
  font-size: 14px;
  color: ${FONT.dark};
`;
export const DB = styled.div`
  margin: 8px 0;
  border-radius: 4px;
  border: 1px solid ${BRAND.primary};
  color: ${BRAND.primary};
  font-weight: bold;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  & > a {
    display: block;
    text-align: center;
    padding: 8px 0;
    width: 100%;
  }
`;
export const OB = styled.div`
  display: flex;
`;
export const WB = styled.div`
  margin-bottom: 8px;
  margin-right: 8px;
  border-radius: 4px;
  border: 1px solid ${BRAND.primary};
  color: ${BRAND.primary};
  font-weight: bold;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
`;

export const CO = styled.div`
  margin-bottom: 8px;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid ${BRAND.primary};
  color: ${BRAND.primary};
  font-weight: bold;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
`;
export const BL = styled.div`
  background-color: ${BRAND.tertiary};
  height: 1px;
  margin-top: 16px;
`;
export const BNS = styled.div`
  color: ${GREYS.black};
  font-size: 14px;
  margin-top: 16px;
  cursor: pointer;
`;
export const BSC = styled.div`
  margin-bottom: 16px;
`;
export const LB = styled.div`
  margin-top: 16px;
  font-size: 14px;
  color: ${GREYS.black};
  cursor: pointer;
`;

export const SI = styled.img`
  height: 16px;
`;

//user account bonus container
export const UBC = styled.div`
  display: flex;
`;

export const SIC = styled.div`
  display: flex;
  align-items: center;
`;

export const ROW = styled.div`
  display: flex;
`;
export const ICON = styled.img`
  display: none;
`;

export const BETCOUNTER = styled.span`
  bottom: 2px;
  position: relative;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  border-radius: 100%;
  margin-left: 6px;
  height: 16px;
  width: 16px;
  display: flex;
  color: white;
  background: ${BRAND.secondaryD};
`;

export const RightArrow = styled.div`
  border: solid ${GREYS.silver};
  border-width: 0 1.5px 1.5px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(-45deg);
`;

export const ShowText = styled.div`
  background: ${BRAND.tertiaryD};
  padding: 4px 8px;
  border-radius: 4px;
  margin: 4px 16px;
  font-size: 10px;
  line-height: 16px;
  font-weight: 400;
`;
