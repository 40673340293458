import styled, { css } from 'styled-components';

export const I = styled.i`
  font-size: ${({ size, topMenu }) =>
    topMenu ? `32px` : size ? `${size}px` : `16px`};
  margin-right: ${({ marginR }) => (marginR ? `${marginR}px` : `0px`)};
  margin-left: ${({ marginL }) => (marginL ? `${marginL}px` : `0px`)};
  padding: ${({ padding }) => (padding ? `${padding}px` : '0px')};
  margin-top: ${({ topMenu }) => topMenu && `8px`}
    ${({ betBooster }) =>
      betBooster &&
      css`
        flex: 0.2;
        margin: 0 !important;
      `};
`;
export const BespokeIcons = styled.img`
  height: 20px;
  width: 20px;
  margin-top: ${({ marginTop }) => marginTop && `${marginTop}px`};
  margin-right: ${({ marginRight }) => marginRight && `${marginRight}px`};
  margin-left: ${({ marginLeft }) => marginLeft && `${marginLeft}px`};
  ${({ categoryTabs }) =>
    categoryTabs &&
    css`
      height: 32px;
      width: 32px;
      margin-block: 4px;
    `}
`;
