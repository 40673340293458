import { setPageParamsToConfig } from './sportsbook/core__sportsbook-utils';
import { getAllCookie } from 'Services/cookie/core__cookies';
import { SiteURL } from 'Services/core__site-url';
export const startApp = (appName, instance, selector = null) => {
  const appDomSelector = !selector
    ? document.querySelectorAll(`[data-app=${appName}]`)
    : document.querySelectorAll(selector);
  appDomSelector.forEach(appSection => {
    if (appSection.classList.contains('fsb-app-loaded')) {
      return false;
    }
    appSection.classList.add('fsb-app-loaded');
    //allow only one events app to be rendered if headers are present, we'll do that part on click
    const appConfig = setAppConfig(appSection);

    //add body page parameters to appconfig
    const config = setPageParamsToConfig(appConfig);
    if (config.siteurl && SiteURL.path === '') {
      SiteURL.set(config.siteurl);
    }

    try {
      if (!config.requestURL) {
        if (SiteURL.path !== '') {
          const path = window.location.pathname.replace(SiteURL.path, '');
          config.requestURL = path;
        } else {
          config.requestURL = window.location.pathname;
        }
      }
    } catch (error) {
      //handle error not required here
    }
    new instance({
      appSection,
      appConfig: config, //final app config
      nodeSSR: false,
      cookies: getAllCookie(),
    });
  });
};

const setAppConfig = appSection => {
  return Object.entries(appSection.dataset).reduce((acc, [k, v]) => {
    // If theres no value, return object
    if (!v) return acc;

    // If value is a number and doesn't contain '+', return value parsed as number
    if (!isNaN(+v) && !`${v}`.includes('+')) return { ...acc, [k]: +v };

    // If value is string true, return boolean true
    if (v === 'true') return { ...acc, [k]: true };

    // If value is string false, return boolean false
    if (v === 'false') return { ...acc, [k]: false };

    // If none of the above, return value as it is
    return { ...acc, [k]: v };
  }, {});
};
