export const translationString = [
  'text.account.detail',
  'text.banking',
  'text.cash.out',
  'text.casino.bonus',
  'text.deposit',
  'text.gambling.controls',
  'text.history',
  'text.kyc',
  'text.log.out',
  'text.my.bets',
  'text.my.account',
  'text.my.lotto.bets',
  'text.open.bets',
  'text.sportsbook.bonus',
  'text.free.bets',
  'text.withdraw',
  'text.free.bets.and.bonuses',
  'oly.self.imposing.measures',
  'text.bonuses',
  'text.nexttier',
  'text.dashboard',
  'text.wallet',
  'text.activate.account',
  'text.terms.and.conditions',
  'text.safer.gambling',
  'text.odds.boost',
  'text.credit_limit',
  'text.lets.chat',
  'text.withdrawable.balance',
  'text.withdrawable.balance.string',
];
