import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';

import { connect } from 'react-redux';
import createStore, {
  setCustomerData,
  getUrl,
  updateAccountBalance,
  updateCasinoBalance,
} from './core__my-account.store';
import ScDecoder from '../../../services/json/core__decoder';
import MyAccountReact from './component/core__my-acccountReact';
import { TranslationsProvider } from 'Services/translations/core__translations';
import { translationString } from './core__my-account-translation';
export const Translations = new TranslationsProvider('MyAccountApp');

class MyAccountApp {
  constructor(properties) {
    Object.assign(this, properties);

    if (this.nodeSSR) {
      Translations.get = properties.Translations.get.bind(
        properties.Translations
      );
      Translations.getAll = properties.Translations.getAll.bind(
        properties.Translations
      );
      return;
    }

    const reduxSelector = document.querySelector(`#redux${this.appSection.id}`);
    reduxSelector
      ? this.start(
          new ScDecoder(JSON.parse(reduxSelector.innerHTML)),
          reduxSelector
        )
      : this.start();
  }

  start(data, reduxSelector) {
    Translations.setAll(data);
    const ReactInstance = this.getReactApp();
    const jsx = (
      <ReduxProvider store={createStore(data || {})}>
        <ReactInstance />
      </ReduxProvider>
    );

    ReactDOM[data ? 'hydrate' : 'render'](jsx, this.appSection);
    if (reduxSelector) reduxSelector.remove();
  }

  getReactApp() {
    this.url = getUrl(this.appConfig);
    const mapStateToProps = () => {
      return {
        url: this.url,
        appConfig: this.appConfig,
      };
    };

    const mapDispatchToProps = {
      url: this.url,
      setCustomerData: setCustomerData,
      updateAccountBalance: updateAccountBalance,
      updateCasinoBalance: updateCasinoBalance,
    };

    MyAccountReact.serverFetch = {
      createStore: createStore,
      fetchData: () => dispatch => {
        return new Promise(resolve => {
          dispatch({ type: 'DO_NOTHING' });
          resolve();
        });
      },
      strToTranslate: translationString,
    };

    return connect(mapStateToProps, mapDispatchToProps)(MyAccountReact);
  }
}

export default MyAccountApp;
