import {
  MAC,
  ANC,
  AC,
  AP,
  BS,
  BN,
  BA,
  DB,
  OB,
  WB,
  CO,
  BL,
  BNS,
  BSC,
  LB,
} from 'CORE__UI/apps/MyAccountApp/core__myAccount';
import styled from 'styled-components';
import { GREYS, BRAND } from '../../globals/colours';

const MAC_J = styled(MAC)`
  background-color: ${GREYS.black};
`;

const BS_J = styled(BS)`
  color: ${GREYS.white};
`;

const BN_J = styled(BN)`
  color: ${GREYS.white};
`;

const DB__J = styled(DB)`
  background-color: ${GREYS.black};
  color: ${BRAND.secondary};
  border-color: ${BRAND.secondary};
  margin-top: 16px;
`;

const WB_J = styled(WB)`
  background-color: ${GREYS.black};
  color: ${BRAND.secondary};
  border-color: ${BRAND.secondary};
`;

const CO_J = styled(CO)`
  background-color: ${GREYS.black};
  color: ${BRAND.secondary};
  border-color: ${BRAND.secondary};
`;

const BNS_J = styled(BNS)`
  color: ${GREYS.white};
`;

const LB_J = styled(LB)`
  color: ${GREYS.white};
`;
const BA_J = styled(BA)`
  color: ${GREYS.white};
`;

const AC_J = styled(AC)`
  color: ${BRAND.secondary};
`;

const AP_J = styled(AP)`
  color: ${BRAND.secondary};
`;

const BL_J = styled(BL)`
  background-color: ${BRAND.primary};
`;

export {
  MAC_J as MAC,
  ANC,
  AC_J as AC,
  AP_J as AP,
  BS_J as BS,
  BN_J as BN,
  BA_J as BA,
  DB__J as DB,
  OB,
  WB_J as WB,
  CO_J as CO,
  BL_J as BL,
  BNS_J as BNS,
  BSC,
  LB_J as LB,
};
