import React from 'react';
import PropTypes from 'prop-types';
import { I } from 'UI/globals/IconsStyles';
import { BespokeIcons } from './core__iconsStyles';
import project from '../../../../project';
import { GLOBAL_CONSTANTS } from 'Services/core__services';

const Icons = ({
  className,
  size,
  iconName,
  marginR,
  marginL,
  padding,
  betBooster,
  info,
  active,
  bespokeSvgSportsIcons,
  category,
  betIcon,
  marginRight,
  marginLeft,
  marginTop,
  categoryTabs,
  color,
  fontWeight,
  topMenu,
}) => {
  category =
    category === GLOBAL_CONSTANTS.ATHLETICS
      ? GLOBAL_CONSTANTS.OLYMPICS
      : category;
  if (bespokeSvgSportsIcons) {
    return active ? (
      <BespokeIcons
        src={`${project.bespokeSvgIconPath}${category}_ON.svg`}
        betIcon={betIcon}
        marginRight={marginRight}
        marginLeft={marginLeft}
        marginTop={marginTop}
        categoryTabs={categoryTabs}
      />
    ) : (
      <BespokeIcons
        src={`${project.bespokeSvgIconPath}${category}.svg`}
        betIcon={betIcon}
        marginRight={marginRight}
        marginLeft={marginLeft}
        marginTop={marginTop}
        categoryTabs={categoryTabs}
      />
    );
  }
  return (
    <I
      size={size}
      marginR={marginR}
      marginL={marginL}
      className={`${className} ${iconName}`}
      padding={padding}
      iconName={iconName}
      betBooster={betBooster}
      info={info}
      active={active}
      style={{ color, fontWeight }}
      topMenu={topMenu}
    />
  );
};

Icons.defaultProps = {
  className: '',
  iconName: '',
};

Icons.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
  iconName: PropTypes.string,
  marginR: PropTypes.number,
  padding: PropTypes.number,
  betBooster: PropTypes.bool,
  info: PropTypes.bool,
  active: PropTypes.bool,
  bespokeSvgSportsIcons: PropTypes.bool,
  category: PropTypes.string,
  betIcon: PropTypes.bool,
  marginRight: PropTypes.number,
  marginLeft: PropTypes.number,
  marginTop: PropTypes.number,
  categoryTabs: PropTypes.bool,
  color: PropTypes.string,
  fontWeight: PropTypes.string,
  marginL: PropTypes.string,
  topMenu: PropTypes.bool,
};

export default Icons;
