import styled from 'styled-components';
import { GREYS, BRAND } from 'UI/globals/colours';

export const BRB = styled('div')`
  display: flex;
  justify-content: space-between;
  background-color: ${GREYS.dove};
  padding: 8px 16px;
  border: 1px solid ${GREYS.dove};
  z-index: 10;
  font-size: 14px;
  font-weight: 400;
`;

export const BRBT = styled('div')`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const BRBBC = styled('div')`
  display: flex;
`;

export const BRS = styled('span')`
  display: flex;
`;

export const BoostIcon = styled.span`
  color: ${BRAND.secondary};
`;
