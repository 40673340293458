import project from '../../project';

// project.js will contain new redirect urls or accountRedirectUrls will be undefined
const { accountRedirectUrls } = project;

// these are the old redirect urls
const coreAccountRedirectUrls = {
  banking: '/my-account/?tab=banking',
  myBets: '/my-account/?tab=my-bets',
  myLottoBets: '/my-account/my-lotto-bets/',
  freeBetsAndBonuses: '/my-account/?tab=free-bonus',
  history: '/my-account/?tab=history',
  gamblingControls: '/my-account/?tab=gambling',
  kyc: '/my-account/?tab=kyc',
  myAccount: '/my-account/?tab=account',
  termsAndConditions: '/terms-and-conditions',
  saferGambling: '/responsible-gambling',
};

// export account redirect urls from project.js with a fallback to old style urls if non-existent in project.js
export default {
  banking: accountRedirectUrls?.banking || coreAccountRedirectUrls.banking,
  myBets: accountRedirectUrls?.myBets || coreAccountRedirectUrls.myBets,
  myLottoBets:
    accountRedirectUrls?.myLottoBets || coreAccountRedirectUrls.myLottoBets,
  freeBetsAndBonuses:
    accountRedirectUrls?.freeBetsAndBonuses ||
    coreAccountRedirectUrls.freeBetsAndBonuses,
  history: accountRedirectUrls?.history || coreAccountRedirectUrls.history,
  gamblingControls:
    accountRedirectUrls?.gamblingControls ||
    coreAccountRedirectUrls.gamblingControls,
  kyc: accountRedirectUrls?.kyc || coreAccountRedirectUrls.kyc,
  myAccount:
    accountRedirectUrls?.myAccount || coreAccountRedirectUrls.myAccount,
  deposit:
    accountRedirectUrls?.deposit ||
    accountRedirectUrls?.banking ||
    coreAccountRedirectUrls.banking,
  withdraw:
    accountRedirectUrls?.withdraw ||
    accountRedirectUrls?.banking ||
    coreAccountRedirectUrls.banking,
  termsAndConditions:
    accountRedirectUrls?.termsAndConditions ||
    coreAccountRedirectUrls.termsAndConditions,
  saferGambling:
    accountRedirectUrls?.saferGambling || coreAccountRedirectUrls.saferGambling,
  preference: accountRedirectUrls?.preference,
};
