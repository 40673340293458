import { Tabs } from '../AccountApp/core__AccountInitialState';

export const appConfig = {
  id: 1,
  appid: '25002742',
  ssr: true,
  app: 'MyAccount',
  categorylist: 'SOCCER',
  categorieslabelref: 'Football,Snooker,Darts,Cricket',
  categoriesref: 'SOCCER,SNOOKER,DARTS,CRICKET',
  link: '/sports/{catRef}',
  requestURL: '/sports/SOCCER',
};

export const finalReduxState = {
  appConfig: {
    id: 1,
    appid: '25002742',
    ssr: true,
    app: 'MyAccount',
    categorylist: 'SOCCER',
    categorieslabelref: 'Football,Snooker,Darts,Cricket',
    categoriesref: 'SOCCER,SNOOKER,DARTS,CRICKET',
    link: '/sports/{catRef}',
    requestURL: '/sports/SOCCER',
  },
  customer: {
    accessToken: 'b4e6283a-bcbd-41ba-94c9-a324daa73b15',
    accountStatus: 'ACTIVE',
    availableBetBoostTokens: 0,
    balance: 892,
    cashOutBetsSummary: {
      bets: 0,
      returns: 0,
    },
    currencyCode: 'GBP',
    dob: 587084400000,
    email: 'sudheer@gmail.com',
    emailMarketing: true,
    firstName: 'sudheer',
    id: 5066,
    kycApproved: true,
    languageId: 1,
    lastName: 'ponnala',
    level: 'LEVEL_ZERO',
    localeKey: 'en',
    marketingOk: true,
    mobile: '8297085253',
    registered: 1610542339149,
    smsMarketing: true,
    sportBonusBalance: 0,
    title: 'Dr',
    tsAndCs: true,
    userName: 'sudheerp',
  },
};

export const redirectUrl = {
  SPORTSBOOK: `/my-account/?tab=${Tabs.FREE_BONUS}&subtab=${Tabs.SPORTSBOOK}`,
  CASINO: `/my-account/?tab=${Tabs.FREE_BONUS}&subtab=${Tabs.CASINO}`,
  FREE_BET: `/my-account/?tab=${Tabs.FREE_BONUS}&subtab=${Tabs.FREE_BET}`,
  SIM: '/my-account/?tab=selfImposingMeasures',
  GAMBLING: `/my-account/?tab=${Tabs.GAMBLING}`,
};
